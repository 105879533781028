import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import ContactHero from '../components/contact-components/contactHero'

const ContactPage = () => (
  <Layout>
    <SEO title="Contact" />
    <ContactHero />
  </Layout>
)

export default ContactPage

import React from 'react'
import ContactForm from './contactForm'

const ContactHero = () => (
  <div className="container max">
    <div className="row py-5 my-5">
      <div className="col-12">
        <h2 className="pb-3">CONTACT</h2>
      </div>
      <div className="col-md-7 pb-5 mb-5">
        <ContactForm />
      </div>
      <div className="col-md-4 offset-md-1">
        <div className="black white-text p-5">
          <h4>DiversiTech Corporation</h4>
          <p>3039 Premiere Parkway<br />
        Suite 600<br />
        Duluth, GA 30097</p>

          <p>(800) 995-2222</p>
        </div>
      </div>
    </div>
  </div>

)
export default ContactHero
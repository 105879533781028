import React from 'react'

const ContactForm = () => (
  <form name="contact" method="post" action="/thankYou" data-netlify="true" data-netlify-honeypot="bot-field">
    <input type="hidden" name="bot-field" />
    <input type="hidden" name="form-name" value="contact" />
    <div className="form-row">
      <div className="form-group col">
        <label htmlFor="name" className="form-label">NAME</label>
        <input type="text" name="name" id="name" className="form-control" required placeholder="Enter your full name" />
      </div>
      <div className="form-group col">
        <label htmlFor="email" className="form-label">EMAIL</label>
        <input type="email" name="email" id="email" className="form-control" required placeholder="Enter your email address" />
      </div>
    </div>

    <div className="form-row mt-3">
      <div className="form-group col">
        <label htmlFor="name" className="form-label">PHONE NUMBER</label>
        <input type="tel" name="phone" id="phone" className="form-control" placeholder="Enter your phone number" />
      </div>
      <div className="form-group col">
        <label htmlFor="preference" className="form-label">CONTACT PREFERENCE</label>
        <div className="d-flex">
          <div className="form-check form-check-inline">
            <input type="radio" name="preference" id="preferEmail" />
            <label htmlFor="preferEmail" className="form-check">Email</label>
          </div>
          <div className="form-check form-check-inline">
            <input type="radio" name="preference" id="preferPhone" />
            <label htmlFor="preferPhone" className="form-check">Phone</label>
          </div>
        </div>
      </div>
    </div>

    <div className="form-row mt-3">
      <div className="form-group col">
        <label htmlFor="message" className="form-label">MESSAGE</label>
        <textarea name="message" id="message" className="form-control" required form-rows="5" placeholder="Enter your message" />
      </div>
    </div>

    <div className="form-row mt-3">
      <div className="form-group col">
        <div className="smallest align-items-start form-check form-check-inline">
          <input className="smallest align-items-start" type="checkbox" name="signup" id="signup" />
          <label htmlFor="signup" className="form-check">By signing up you agree to receive emails from DiversiTech with news, special offers, promotions and other information. You can unsubscribe at any time.</label>
        </div>
      </div>
    </div>

    <button className="btn--dark mt-2" type="submit">SUBMIT</button>

  </form>

)
export default ContactForm